$(function () {

	//タブ切り替え
	$(".tab_label").on("click",function(){
		var $th = $(this).index();
		$(this).parent().find(".tab_label").removeClass("active");
		$(this).parent().next().find(".tab_panel").removeClass("active");
		$(this).addClass("active");
		$(this).parent().next().find(".tab_panel").eq($th).addClass("active");
	});

	//アコーディオン
	$(".acd_head").on("click", function() {
		$(this).parent().toggleClass('open');
		$(this).toggleClass('open');
		$(this).next().slideToggle();
	});

});