$(window).on("load",function(){
});
$(function(){

	var $body = $('body');
	
	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$('body').prepend(svg);
	});

	//ページ内スクロール
	$.smooziee();

	//スクロールしたらクラスを付与
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {
			$body.addClass("scroll");
		} else {
			$body.removeClass("scroll");
		}
	});

});